import YTvideoss from "./ytvideos";

const WorkoutPage = () => {
  return (
    <>
      <YTvideoss></YTvideoss>
    </>
  );
};

export default WorkoutPage;
